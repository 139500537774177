import {
  Box,
  Button,
  Divider,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover,
  SvgIcon,
  Typography,
} from "@mui/material";
import UserIcon from "@mui/icons-material/AccountCircle";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import { type FC, useCallback } from "react";
import { RouterLink } from "@/components/core/router-link";
import { useAuth } from "src/hooks/use-auth";
import { useRouter } from "src/hooks/use-router";
import { paths } from "src/paths";
import { SwitchAccountOutlined } from "@mui/icons-material";
import { checkIsSuperUser } from "src/utils/auth";
import * as config from "src/config";
import toast from "react-hot-toast";

interface AccountPopoverProps {
  anchorEl: null | Element;
  onClose?: () => void;
  open?: boolean;
}

export const AccountPopover: FC<AccountPopoverProps> = (props) => {
  const { anchorEl, onClose, open, ...other } = props;

  const router = useRouter();
  const auth = useAuth();
  const user = auth.user;
  const isAllGood = checkIsSuperUser(user);

  const handleCopyToken = useCallback(async (): Promise<void> => {
    try {
      await navigator.clipboard.writeText(auth.token ?? "");
      toast.success("Token copied to clipboard!");
    } catch (err) {
      console.error("Failed to copy token: ", err);
      toast.error("Failed to copy access token");
    }
  }, [auth.token]);

  const handleCopyEnv = useCallback(async (): Promise<void> => {
    const envLines = [
      `export AG_ENDPOINT="${config.API_SERVER}"`,
      `export AG_TOKEN="${auth.token}"`,
    ].join("\n");

    try {
      await navigator.clipboard.writeText(envLines);
      toast.success("Credentials copied to clipboard!");
    } catch (err) {
      console.error("Failed to copy token: ", err);
      toast.error("Failed to copy credentials");
    }
  }, [auth.token, config.API_SERVER]);

  const handleLogout = useCallback(async (): Promise<void> => {
    try {
      onClose?.();

      // switch (auth.issuer) {
      //   case Issuer.JWT: {
      //     await auth.signOut();
      //     break;
      //   }
      //
      //   default: {
      //     console.warn("Using an unknown Auth Issuer, did not log out");
      //   }
      // }
      await auth.signOut();

      router.push(paths.index);
    } catch (err) {
      console.error(err);
      toast.error("Something went wrong!");
    }
  }, [auth, router, onClose]);

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: "center",
        vertical: "bottom",
      }}
      disableScrollLock
      onClose={onClose}
      open={!!open}
      PaperProps={{ sx: { width: 240 } }}
      {...other}
    >
      <Box component="div" sx={{ p: 2 }}>
        <Typography variant="body1">
          {user?.profile.firstName} {user?.profile.lastName}
        </Typography>
        <Typography color="text.secondary" variant="body2">
          {user?.email}
        </Typography>
        <Typography color="text.secondary" variant="body2">
          {user?.tenant.name} [ {user?.tenant.shortId} ]
        </Typography>
        <Typography color="text.secondary" variant="body2">
          Role: {user?.userRole}
        </Typography>
        {user?.userType === "SUPERUSER" && (
          <Typography color="red" variant="body2" fontWeight={"bold"}>
            [SUPERUSER]
          </Typography>
        )}
      </Box>
      <Divider />
      <Box component="div" sx={{ p: 1 }}>
        <ListItemButton
          component={RouterLink}
          href={paths.settings.account}
          onClick={onClose}
          sx={{
            borderRadius: 1,
            px: 1,
            py: 0.5,
          }}
        >
          <ListItemIcon sx={{ mr: 1 }}>
            <SvgIcon fontSize="small">
              <UserIcon />
            </SvgIcon>
          </ListItemIcon>
          <ListItemText
            primary={<Typography variant="body2">Profile</Typography>}
          />
        </ListItemButton>

        {isAllGood && (
          <>
            <ListItemButton
              onClick={onClose}
              sx={{
                borderRadius: 1,
                px: 1,
                py: 0.5,
              }}
            >
              <ListItemIcon sx={{ mr: 1 }}>
                <SvgIcon fontSize="small">
                  <CreditCardIcon />
                </SvgIcon>
              </ListItemIcon>
              <ListItemText
                onClick={handleCopyToken}
                primary={
                  <Typography variant="body2"> Copy Access Token </Typography>
                }
              />
            </ListItemButton>
            <ListItemButton
              onClick={onClose}
              sx={{
                borderRadius: 1,
                px: 1,
                py: 0.5,
              }}
            >
              <ListItemIcon sx={{ mr: 1 }}>
                <SvgIcon fontSize="small">
                  <CreditCardIcon />
                </SvgIcon>
              </ListItemIcon>
              <ListItemText
                onClick={handleCopyEnv}
                primary={
                  <Typography variant="body2">
                    Copy Credentials (.env)
                  </Typography>
                }
              />
            </ListItemButton>
          </>
        )}

        {isAllGood ? (
          <ListItemButton
            component={RouterLink}
            href={paths.profile.switchTenant}
            onClick={onClose}
            sx={{
              borderRadius: 1,
              px: 1,
              py: 0.5,
            }}
          >
            <ListItemIcon sx={{ mr: 1 }}>
              <SvgIcon fontSize="small">
                <SwitchAccountOutlined />
              </SvgIcon>
            </ListItemIcon>
            <ListItemText
              primary={<Typography variant="body2">Switch Tenant</Typography>}
            />
          </ListItemButton>
        ) : null}
      </Box>
      <Divider sx={{ my: "0 !important" }} />
      <Box
        component="div"
        sx={{
          display: "flex",
          p: 1,
          justifyContent: "center",
        }}
      >
        <Button color="inherit" onClick={handleLogout} size="small">
          Logout
        </Button>
      </Box>
    </Popover>
  );
};
