import { lazy } from "react";
import type { RouteObject } from "react-router";
import { Outlet } from "react-router-dom";
import { paths } from "../paths";

import { GuestGuard } from "src/guards/guest-guard";
import { Layout as AuthLayout } from "src/layouts/auth/modern-layout";

const LoginPage = lazy(() => import("src/pages/auth/jwt/login"));
const LoginCallbackPage = lazy(() => import("src/pages/auth/jwt/code"));
const ManualLoginPage = lazy(() => import("src/pages/auth/manual-login"));

export const authRoutes: RouteObject[] = [
  {
    element: (
      <GuestGuard>
        <AuthLayout>
          <Outlet />
        </AuthLayout>
      </GuestGuard>
    ),
    children: [
      {
        path: paths.auth.login,
        element: <LoginPage />,
      },
      {
        path: paths.auth.loginCallback,
        element: <LoginCallbackPage />,
      },
      {
        path: paths.auth.manualLogin,
        element: <ManualLoginPage />,
      },
    ],
  },
];
