export const paths = {
  index: "/",
  auth: {
    login: "/login",
    loginCallback: "/code",
    manualLogin: "/login/manual",
  },
  dashboard: {
    index: "/",
  },
  admin: {
    index: "/admin",
    overview: "/admin/overview",

    takeout: {
      import: "/admin/takeout/import",
      export: "/admin/takeout/export",
      outline: "/admin/takeout/outline",
    },

    workflows: "/data/workflows",
  },
  profile: {
    index: "/profile",
    switchTenant: "/switch-tenant",
    switchUserType: "/switch-role",
  },
  settings: {
    index: "/settings",

    account: "/settings/account",
    notifications: "/settings/notifications",
    security: "/settings/security",
    billing: "/settings/billing",
    team: "/settings/team",
    tenant: "/settings/tenant",
    handlers: "/settings/handlers",
    chatThreads: "/settings/chat-threads",

    integrations: "/settings/integrations",
    createIntegration: "/settings/integrations/create",
    configureIntegration: "/settings/integrations/:integrationId",
  },
  worksheets: {
    index: "/worksheets",
    worksheet: "/worksheets/:worksheetId", // usually a redirect
    items: "/worksheets/:worksheetId/items",
    flow: "/worksheets/:worksheetId/flow",
    chatSetup: "/worksheets/:worksheetId/chat/setup",
    resultChat: "/worksheets/:worksheetId/items/:itemId/steps/:stepId/chat",
    settings: "/worksheets/:worksheetId/settings",
    item: "/worksheets/:worksheetId/item/:itemId",
  },
  agents: {
    index: "/lab/skills",
    configure: "/lab/skills/:labId",
  },
  teams: {
    index: "/lab/teams",
    configure: "/lab/teams/:labId",
  },
  tools: {
    index: "/lab/tools",
    configure: "/lab/tools/:labId",
  },

  notAuthorized: "/401",
  notFound: "/404",
  serverError: "/500",
} as const;
