import {
  type WorksheetConfig,
  type WorksheetLink,
} from "allgood-api/src/repos/worksheet.schema";
import { type IntegrationType } from "allgood-api/src/services/integration/integration.schema";
import { Stack, Typography } from "@mui/material";
import { capitalCase } from "change-case";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import LinkIcon from "@mui/icons-material/Link";
import { ArrowSquareOut, CirclesThree, SlackLogo } from "@phosphor-icons/react";
import { Link } from "react-router-dom";

export default function WorksheetLinks({
  config,
}: {
  config: WorksheetConfig;
}) {
  return (
    <Stack mt={1}>
      {config.links.map((link, i) => (
        <WorksheetLinkRow link={link} key={i} />
      ))}
    </Stack>
  );
}

export function WorksheetLinkRow({ link }: { link: WorksheetLink }) {
  const linkDetails = LINKS[`${link.integration} ${link.linkType}`];
  const icon = linkDetails?.icon ?? <LinkIcon sx={{ fontSize: "20px" }} />;

  const title = link.display?.title ?? capitalCase(link.linkType);

  const isLink = link.display?.url;
  const urlProps = link.display?.url
    ? {
        component: Link,
        to: link.display.url,
        rel: "noopener noreferrer",
        target: "_blank",
      }
    : {};

  return (
    <Stack
      {...urlProps}
      mt={1}
      px={1}
      py={1}
      sx={{
        border: "1px solid #ddd",
        borderRadius: "12px",
        color: "inherit",
        textDecoration: "none",
      }}
      direction="row"
      alignItems="center"
      spacing={1}
    >
      <Stack width={20} height={20} justifyContent="center" alignItems="center">
        {icon}
      </Stack>
      <Stack>
        <Typography color="secondary" variant="overline" sx={{ lineHeight: 1 }}>
          {link.integration}
        </Typography>
        <Typography variant="body2">{title}</Typography>
      </Stack>
      {isLink && (
        <Stack ml="auto">
          <ArrowSquareOut size={18} color="#333" />
        </Stack>
      )}
    </Stack>
  );
}

export const LINKS: Record<
  `${IntegrationType} ${string}`,
  { icon: React.ReactNode }
> = {
  "MARKETO PROGRAM": {
    icon: <BusinessCenterIcon sx={{ fontSize: "20px" }} color="secondary" />,
  },
  "SLACK CHANNEL": {
    icon: <SlackLogo size={32} />,
  },
  "ASANA TASK": {
    icon: <CirclesThree size={32} />,
  },
};
