import { lazy } from "react";
import type { RouteObject } from "react-router";
import { paths } from "src/paths";

const WorksheetFlowPage = lazy(() => import("src/pages/worksheet/flow-page"));
const WorksheetSettingsPage = lazy(
  () => import("src/pages/worksheet/settings-page"),
);
const WorksheetChatSetupPage = lazy(
  () => import("src/pages/worksheet/worksheet-chat-setup-page"),
);
const WorksheetItemsPage = lazy(() => import("src/pages/worksheet/items-page"));
const WorksheetLandingPage = lazy(
  () => import("src/pages/worksheet/worksheet-landing"),
);

const WorksheetItemPage = lazy(() => import("src/pages/worksheet/item-page"));

export const worksheetRoutes: RouteObject[] = [
  {
    path: paths.worksheets.worksheet,
    element: <WorksheetLandingPage />,
  },
  { path: paths.worksheets.chatSetup, element: <WorksheetChatSetupPage /> },
  { path: paths.worksheets.flow, element: <WorksheetFlowPage /> },
  { path: paths.worksheets.settings, element: <WorksheetSettingsPage /> },
  { path: paths.worksheets.items, element: <WorksheetItemsPage /> },
  { path: paths.worksheets.item, element: <WorksheetItemPage /> },
];
