import { z } from "zod";

export const SHORT_ID_ALPHABET = "23456789ABCDEFGHIJKLMNOPQRSTUVWXYZ";

export const agIdBrand = "AgId Brand";
export const shortIdBrand = "ShortId Brand";

export type AgId<T extends string> = string & { [agIdBrand]: T };

export type ShortId<T extends string> = string & { [shortIdBrand]: T };

type IsStringLiteral<T extends string> = string extends T ? never : T;

/** Create an ID schema for this particular AgId type. */
function makeIdSchema<T extends string>(
  name: IsStringLiteral<T>,
): z.ZodType<AgId<T>, z.ZodTypeDef, AgId<T>> {
  const schema = z
    .string({ invalid_type_error: `${name} must be a string` })
    .uuid({ message: `${name} must be a valid UUID` })
    .refine((_id: string): _id is AgId<T> => true);

  // NOTE: We need this to ensure that `z.input<SomeSchema>` has `AgId<T>` for
  // its ID fields, and not `string`. This is required to implement a type-safe
  // BaseRepo. Ask Will if you've got questions, because this gets very deep
  // in the weeds.
  return schema as unknown as z.ZodType<AgId<T>, z.ZodTypeDef, AgId<T>>;
}

/** Create an ID schema for this particular Short ID type. */
function makeShortIdSchema<T extends string>(
  name: IsStringLiteral<T>,
): z.ZodType<AgId<T>, z.ZodTypeDef, AgId<T>> {
  // Slightly more permissive than SHORT_ID_ALPHABET.
  const regex = new RegExp(`^[A-Z0-9]{8}$`);
  const schema = z
    .string({ invalid_type_error: `${name} must be a string` })
    .regex(regex, { message: `${name} must be a valid short ID` })
    .refine((_id: string): _id is AgId<T> => true);

  // NOTE: We need this to ensure that `z.input<SomeSchema>` has `AgId<T>` for
  // its ID fields, and not `string`. This is required to implement a type-safe
  // BaseRepo. Ask Will if you've got questions, because this gets very deep
  // in the weeds.
  return schema as unknown as z.ZodType<AgId<T>, z.ZodTypeDef, AgId<T>>;
}

export type AnyAgId = AgId<string>;
export const AnyAgId: z.ZodType<AnyAgId, z.ZodTypeDef, AnyAgId> =
  makeIdSchema("Any");

export const TenantId = makeIdSchema("TenantId");
export type TenantId = z.output<typeof TenantId>;

export const TenantShortId = makeShortIdSchema("TenantShortId");
export type TenantShortId = z.output<typeof TenantShortId>;

export const ALLGOOD_TENANT_ID = TenantId.parse(
  "0188fffc-ea1c-5419-1813-2749c37e1739",
);

export const ALLGOOD_TENANT_SHORT_ID = TenantShortId.parse("ALLGOOD0"); //  8 chars for shortId

export const UserId = makeIdSchema("UserId");
export type UserId = z.output<typeof UserId>;

export const UserShortId = makeShortIdSchema("UserShortId");
export type UserShortId = z.output<typeof UserShortId>;

export const UserTenantMappingId = makeIdSchema("UserTenantMappingId");
export type UserTenantMappingId = z.output<typeof UserTenantMappingId>;

export const GuideId = makeIdSchema("GuideId");
export type GuideId = z.output<typeof GuideId>;

export const AgentId = makeIdSchema("AgentId");
export type AgentId = z.output<typeof AgentId>;

export const TeamConfigId = makeIdSchema("TeamConfigId");
export type TeamConfigId = z.output<typeof TeamConfigId>;

export const WorkflowRunId = makeIdSchema("WorkflowRunId");
export type WorkflowRunId = z.output<typeof WorkflowRunId>;

export const WorkflowCheckpointId = makeIdSchema("WorkflowCheckpointId");
export type WorkflowCheckpointId = z.output<typeof WorkflowCheckpointId>;

export const IntegrationId = makeIdSchema("IntegrationId");
export type IntegrationId = z.output<typeof IntegrationId>;

export const TokenCacheId = makeIdSchema("TokenCacheId");
export type TokenCacheId = z.output<typeof TokenCacheId>;

export const ThreadId = makeIdSchema("ThreadId");
export type ThreadId = z.output<typeof ThreadId>;

export const ThreadRefId = makeIdSchema("ThreadRefId");
export type ThreadRefId = z.output<typeof ThreadRefId>;

export const WorksheetId = makeIdSchema("WorksheetId");
export type WorksheetId = z.output<typeof WorksheetId>;

export const StepId = makeIdSchema("StepId");
export type StepId = z.output<typeof StepId>;

export const ItemId = makeIdSchema("ItemId");
export type ItemId = z.output<typeof ItemId>;

export const LabItemId = makeIdSchema("LabItemId");
export type LabItemId = z.output<typeof LabItemId>;

export const ResultId = makeIdSchema("ResultId");
export type ResultId = z.output<typeof ResultId>;

export const ToolId = makeIdSchema("ToolId");
export type ToolId = z.output<typeof ToolId>;

export const BatchId = makeIdSchema("BatchId");
export type BatchId = z.output<typeof BatchId>;

export const WebhookId = makeIdSchema("WebhookId");
export type WebhookId = z.output<typeof WebhookId>;

export const ItemWebhookId = makeIdSchema("WebhookId");
export type ItemWebhookId = z.output<typeof ItemWebhookId>;

export const DocumentId = makeIdSchema("DocumentId");
export type DocumentId = z.output<typeof DocumentId>;

export const CacheId = makeIdSchema("CacheId");
export type CacheId = z.output<typeof CacheId>;

export const ItemStateCacheId = makeIdSchema("ItemStateCacheId");
export type ItemStateCacheId = z.output<typeof ItemStateCacheId>;

export const ResultStateCacheId = makeIdSchema("ResultStateCacheId");
export type ResultStateCacheId = z.output<typeof ResultStateCacheId>;

export const FileImportId = makeIdSchema("FileImportId");
export type FileImportId = z.output<typeof FileImportId>;

export const ModelId = makeIdSchema("ModelId");
export type ModelId = z.output<typeof ModelId>;

export const ConfigHistoryId = makeIdSchema("ConfigHistoryId");
export type ConfigHistoryId = z.output<typeof ConfigHistoryId>;

export const MessageId = makeIdSchema("MessageId");
export type MessageId = z.output<typeof MessageId>;

export const ExecuteId = makeIdSchema("ExecuteId");
export type ExecuteId = z.output<typeof ExecuteId>;

export const UploadcareFileId = makeIdSchema("UploadcareFileId");
export type UploadcareFileId = z.output<typeof UploadcareFileId>;
